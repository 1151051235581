<style lang="scss" scoped>
    .menu-home {
        z-index: 10;
        /*position: absolute;*/
        /*left: 0;*/
        /*right: 0;*/
        /*top: 0;*/
        /*bottom: 0;*/
        background: #fff;

        .home-logo {
            padding: 20px 12px;
            display: flex;
            align-items: center;
        }
        .home-logo_l {
            position: relative;
            &:before {
                content: "";
                display: block;
                height: 36px;
                width: 1px;
                background: #e5e5e5;
                position: absolute;
                right: -5px;
                top: 50%;
                margin-top: -18px;

            }
            img {
                height: 46px;
            }

        }
        .home-logo_r {
            padding-left: 10px;
            font-size: 12px;
        }
        .home-card {
            padding: 0 12px;
            li {
                height: 100px;
                display: flex;
                align-items: center;
                background: #f8f8f8;
                border-radius: 6px;
                margin-bottom: 10px;
                padding-left: 30px;
                font-size: 14px;
                color: #999999;
                position: relative;
            }
            li.card-order-manage {
                padding: 0;
                img {
                    width: 100%;
                    height: 100%;
                }
                .order-manage_e {
                    position: absolute;
                    left: 0;
                    right: 0;
                    height: 100px;
                    display: flex;
                    align-items: center;
                    padding-left: 30px;
                }

            }
            .order-driver_num {
                position: absolute;
                right: -4px;
                top: -6px;
                height: 18px;
                line-height: 18px;
                min-width: 18px;
                border-radius: 50%;
                background: red;
                text-align: center;
                font-size: 14px;
                color: #fff;
                padding: 2px;
                span {
                    display: inline-block;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    width: 18px;
                }
            }
            .order-title {
                font-size: 20px;
                font-weight: 700;
                color: #333;
                margin-bottom: 4px;
            }
            .order-desc {
                font-size: 12px;
                color: #fff;
                background: #333;
                padding: 0 5px;
                border-radius: 2px;
                text-align: center;
                line-height: 20px;
            }

        }
        .home-foot {
            position: absolute;
            right: 22px;
        }

    }
</style>
<template>

    <div class="menu-home">
        <div class="home-logo">
            <div class="home-logo_l">
                <img src="@/assets/images/logo_full.png">
            </div>
            <div class="home-logo_r" v-if="userInfo && userInfo.loginuserDto">
                <p>{{userInfo.companyName}}</p>
                <p>用户名：{{userInfo.loginuserDto.loginName}}</p>
            </div>
            <div class="home-foot">
                <mt-button @click="switchAccount" :outline="true" :inline="true" :primary="true">切换账号</mt-button>
            </div>
        </div>
        <ul class="home-card">
            <li class="card-order-manage" @click="goToOrder(vm.path)" v-for="(vm,index) in list" :key="index">
                <img :src="vm.imgUrl" alt="">
                <div class="order-manage_e">
                    <div>
                        <p class="order-title">{{vm.title}}</p>
                        <p class="order-desc" v-if="vm.subTitle">{{vm.subTitle}}&nbsp;></p>
                    </div>
                </div>
                <div class="order-driver_num" v-if="orderState&&index===0">
                    <span>{{orderState.count}}</span>
                </div>
            </li>
            <!--<li>-->
            <!--<p>更多功能敬请期待…</p>-->
            <!--</li>-->
        </ul>
    </div>

</template>

<script type="text/ecmascript-6">
    import {clearUserLoginInfo} from '@/common/js/user/';
    import {Toast} from 'mint-ui';
    import {Button} from 'cube-ui'

    export default {
        name: 'home',
        //定义模版数据
        data() {
            return {
                orderState: null,
                list: [
                    {
                        title: '用车订单管理',
                        subTitle: '',
                        imgUrl: require('../../assets/home/car_order.jpg'),
                        path: 'orderManage'
                    },
                    {
                        title: '旅游订单管理',
                        subTitle: '',
                        imgUrl: require('../../assets/home/trip_order.jpg'),
                        path: 'tripOrderList'
                    },
                    {
                        title: '客服下单',
                        subTitle: '',
                        imgUrl: require('../../assets/home/service_order.jpg'),
                        path: 'demandOrder'
                    }
                ]
            }
        },
        components: {
            MtButton: Button
        },
        //计算属性
        computed: {
            userInfo: function () {
                return this.$store.state.loginUserInfo
            }
        },
        //主件被加载完成
        mounted() {
            this.getOrderState();
        },
        //定义事件方法
        methods: {
            goToOrder(name) {
                this.$router.push({
                    name
                })
            },
            async getOrderState() {
                this.loadingShow();
                let res = await this.http('/galaxyOrder/orderCountByStatus/31');
                if (res.success && res.data) {
                    this.orderState = res.data.orderStatus[0]
                }
                this.loadingHide()
            },

            async loginOut(_this) {
                return await _this.http('/galaxyLogin/logout', null, "POST", false);
            },
            switchAccount() {
                this.loginOut(this);
                clearUserLoginInfo(this);
                this.$router.replace('/login');
                return Toast("退出登录成功!");
            }
        },
        //监听模版变量
        watch: {}

    }
</script>
